//
//
// Menu container
//
@keyframes dropdownarrow {
    0% {
        border-top: 0.25em solid;
        border-bottom: 0.0em solid transparent;
    }
    100% {
        border-top: 0.0em solid transparent;
        border-bottom: 0.25em solid;
    }
}
@keyframes dropdownarrowreverse {
    0% {
        border-top: 0.0em solid transparent;
        border-bottom: 0.25em solid;
    }
    100% {
        border-top: 0.25em solid;
        border-bottom: 0.0em solid transparent;
    }
}

body.main-nav-open {
    overflow: hidden;
}

@import "../assets/sidebar-menu.scss";

.sidebar-menu {
    &-wrapper {
        color: $gray-500;
        font-size: $font-size-sm;
        background-color: rgba(0, 0, 0, 0);
        // opacity: 0;
        transition: transform 0.8s ease, opacity 0.5s ease;
        &:before {
            background-color: transparent;
            background-position: 100% 50%;
            background-repeat: no-repeat;
            transition: background-color 0.3s ease-out;

            // transform: scaleX(0);
            // transform-origin: 100% 50%;
            // transition: transform 0.8s ease;
            .main-nav-open & {
                background-color: $primary;

                // transform: scaleX(1);
                // transition: transform 0.01s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s ease 0.01s;
            }
        }
        .main-nav-open & {
            background-color: rgba(0, 0, 0, 0.6);
            transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1), background-color 1s cubic-bezier(.4,0,.2,1) .2s;
            // opacity: 1;
            // transition: transform 0.01s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s ease 0.01s;
        }
        a,
        .close-menu {
            color: $white;
            &:hover,
            &:focus {
                color: $gray-500;
            }
        }
        .dropdown {
            &.show {
                .dropdown-toggle {
                    &:after {
                        animation: dropdownarrow 0.5s linear 1;
                        border-top: 0.0em solid transparent;
                        border-bottom: 0.25em solid;
                    }
                }
            }
        }
        .dropdown-toggle {
            &:after {
                margin-left: 0.75em;
                vertical-align: middle;
                border-top: .25em solid;
                border-right: .25em solid transparent;
                border-bottom: 0.0em solid transparent;
                border-left: .25em solid transparent;
                animation: dropdownarrowreverse 0.3s linear 1;
            }
        }
        .dropdown-menu {
            position: static !important;
            float: none;
            background-color: transparent;
            margin: 0.5rem 0;
            padding: 0;
            border: none;
            font-size: 0.7em;
            display: block;
            max-height: 0;
            overflow: hidden;
            transform: none !important;
            transition: max-height 0.3s;
            overflow: hidden;
            &.show {
                max-height: 400px;
                transition: max-height 1.5s;
                > li {
                    opacity: 1;
                    transform: translateX(0);
                    transition: transform 0.5s ease, opacity 0.5s ease;
                    @for $i from 0 through 20 {
                        &:nth-child(#{$i+1}) {
                            transition-delay: $i * 0.12s;
                        }
                    }
                }
            }
            > li {
                // margin-bottom: 15px;
                // margin-top: 15px;
                transform: translateX(120px);
                opacity: 0;
                transition: transform 0.01s linear, opacity 0.01s linear;
            }
        }
        .dropdown-item {
            padding: 10px 15px;
            display: inline-block;
            vertical-align: top;
            width: auto;
            line-height: 1em;
            
            &:focus, 
            &:hover {
                background-color: transparent;
                color: #999;
            }
        }
    }
    &-container {
        &__inner {
            &__col {
                // color: $white;
                background-color: transparent;
                transition: background-color 0.3s ease-out;
                .main-nav-open & {
                    // background-color: $black;
                }
            }
        }
    }
    &-content {
        &.no-sidebar-footer {
            padding-bottom: 30px;
            @include media-breakpoint-up(sm) {
                padding-bottom: 60px;
            }
        }
    }
    &-header {
        flex-grow: 0;
        flex-shrink: 0;
    }
    &-body {
        padding-top: 10px;
        flex-basis: auto;
        // @include media-breakpoint-up(sm) {
        //     padding-top: 60px;
        // }
        .menu {
            > .menu-item {
                > a {
                    display: inline-block;
                    width: auto;
                }
            }
        }
    }
    &-footer {
        flex-grow: 1;
        flex-shrink: 1;
    }
}

.header-widget {
    &-item {
        @extend .widget-item;
        margin-bottom: 2rem;

        .menu {
            @extend .row;
            margin-left: 0;
            margin-right: 0;
            > li.menu-item {
                @extend .col-12;
                // &:first-child {
                //     padding-left: 0;
                // }
                // &:last-child {
                //     padding-right: 0;
                // }
            }
            > li.menu-item.lang-item {
                @extend .col-auto;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
        & .menu-social-container{
            & .menu {
                > li.menu-item {
                    @extend .col-auto;
                    display: flex;
                    align-items: center;

                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
    &-title {
        @extend .widget-title;
        font-size: $font-size-sm;
    }
}

.menu-mainmenu {
    &-wrapper {
        .menu {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            > li.menu-item {
                font-size: 28px;
                // line-height: 1.125em;
                // margin-bottom: 1em;
                line-height: 1.3333333em;
                // margin-bottom: 0.3666666em;

                & a{
                    padding-left: 0;
                }
            }
        }
    }
    &-container {
        margin-top: 20px;
        margin-bottom: 2rem;
        .header-widget-title {
            margin-bottom: 1.31818181em;
            font-size: $font-size-sm;
        }
    }
}

.menu-widgetarea {
    &-bottom {
        & #menu-more-info li{
            padding-left: 0;
        }

        &__social {
            @extend .widget-item-social;
            margin-left: 0;
            margin-right: 0;
            margin-top: 10px;
            width: 100%;
            justify-content: flex-start;
            @include media-breakpoint-up(md){
                justify-content: space-between;
                max-width: 200px;
            }
            > div {
                padding-left: 0;
            }
        }
    }
}

.navbar{
    height: $navbar-height; //96px

    &-toggler {
        padding: 0;
        border: none;
        vertical-align: top;
        &-label {
            vertical-align: middle;
        }
        &-close {
            display: inline-block;
            vertical-align: middle;
            > svg {
                width: 20px;
                height: 20px;
            }
        }
    }
    &-brand{
        transition: all 0.5s ease;
        position: relative;
        color: $primary!important;
        height: 50px;
        z-index: 1032;
        &>svg {
            height: 50px;
            width: auto;
        }
    }

    &.bg-primary,
    &.bg-page{
        & .navbar-brand{
            color: $white!important;
        }
    }
}

.main-nav-open{
    & .navbar{
        // padding-right: 17px;
        margin-right: 17px;
    }
    & .navbar-brand{
        color: $white !important;
        &>svg {
            position: fixed;
            z-index: 1032;
        }
    }
}

body {
    &.main-nav-open {
        padding-right: 17px;
    }
}

.social-link{
    & span {
        @include sr-only()
    }
}
