.row--aree-interventi,
.row--post-recenti,
.row--post-sostegni {
    // margin-top: 2.5rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding-bottom: calc(60px - 2.5rem);
    color: $gray-600;
    .pagination,
    > div[class^="col"] {
        margin-bottom: 2.5rem;
    }
    .card-title {
        color: $gray-800;
        font-size:  1rem;
        font-weight: 500;
        margin-bottom: 0px;
        text-transform: none;
    }
    a {        
        &:hover,
        &:focus,
        &:active {   
            .card-title {
                color: $primary;
            }
        }
    }
    p,
    .card-text {
        font-size: 0.875rem;
        margin-bottom: 0px;
    }
    .card-body {
        padding-right: 0px;
        padding-left: 0px;
        position: relative;
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: -5px;
            left: 2.5rem;
            height: 10px;
            width: calc(100% - 2.5rem);
            background-color: $primary;
        }
    }
    .card {
        // overflow: hidden;
        border: 0px;
        border-radius: 0px;
        height: 100%;
        .card-img, .card-img-top {
            border-radius: 0px;
            // height: 205px;
            // height: 316px;
            height: 100%;
            object-fit: cover;
        }
        .position-relative {
            background-color: $white;
            // img {
            //     mix-blend-mode: multiply;
            // }
            .btn {
                font-weight: 500;
            }
            .card-link {
                position: relative;
                display: block;
                > .overlay {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: block;
                    background-color: $white;
                    mix-blend-mode: multiply;
                    opacity: 0;
                    transition: opacity 0.35s ease;
                }
                // &:hover {
                //     > .overlay {
                //         opacity: 0.4;
                //         transition: opacity 0.15s linear;
                //     }
                // }
            }
            // &:hover {
            //     &.bg-incontri {
            //         background-color: $green;
            //         a {
            //             > .overlay {
            //                 background-color: $green;
            //             }
            //         }
            //     }
            //     &.bg-blog {
            //         background-color: $secondary;
            //         a {
            //             > .overlay {
            //                 background-color: $secondary;
            //             }
            //         }
            //     }
            //     &.bg-notizie {
            //         background-color: $primary;
            //         a {
            //             > .overlay {
            //                 background-color: $primary;
            //             }
            //         }
            //     }
            //     &.bg-storie {
            //         background-color: $yellow;
            //         a {
            //             > .overlay {
            //                 background-color: $yellow;
            //             }
            //         }
            //     }
            // }
        }
        &.card-post--nocover {
            color: $gray-800;
            .card-link {
                background-color: $gray-100;
                color: $gray-500;
            }
            svg {
                padding: 3.75rem !important;
            }
            .position-relative {
                &:hover {
                    &.bg-storie,
                    &.bg-notizie,
                    &.bg-blog,
                    &.bg-incontri {
                        background-color:$gray-100;
                        a {
                            > .overlay {
                                background-color: $gray-100;
                            }
                        }
                    }
                }
            }
        }
    }
    .dropdown-toggle::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }
    .btn {
        position: absolute;
        left: 0px;
        bottom: 0px;
        min-width: 50%;
        text-align: left;
    }
    .position-sticky {
        top: 65px;
    }

    
    @include media-breakpoint-down(sm){
        padding-bottom: calc(1.875rem - 1.25rem);
        .pagination,
        > div[class^="col"] {
            margin-bottom: 1.25rem;
        }
    }
}
.row--post-recenti {
    .card {
        .row {
            margin-bottom: .5rem;
            .card-body {
                padding-left: .5rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
        &:last-child() {
            .row {
                margin-bottom: 0px;
            }
        }
    }
    .card-body {
        &::before {
            content: "";
            display: none;
        }
        span {
            color: $gray-500;
            margin-bottom: 1rem;
            font-weight: 700;
            font-size: 0.875rem;
            display: inline-block;
        }
    }
    
    @include media-breakpoint-down(sm){
        .card {
            .row {
                .card-body {
                    padding-left: 0px;
                }
            }
        }
	}
}

.shortcode-title-wrapper {
    @include media-breakpoint-up(md){
        display:  flex;
    }
    margin-bottom: 0px !important;
    @include media-breakpoint-down(sm){
        margin-bottom: 1.25rem !important;
	}
}

.list-category {
    text-transform: uppercase;
    font-size: 20px;
    .row & {
        min-height: 0;
        margin-bottom: 0;
    }
    a {
        margin-right: 30px;
        margin-bottom: 1.25rem;
        font-weight: 500;
        &:last-child {
            margin-right: 0;
        }
    }
    &__link {
        &--notizie {
            
        }
        &--incontri {
            color: $green;
        }
        &--storie {
            color: $yellow;
        }
        &--blog {
            color: $secondary;
        }
    }
}

.btn-incontri {
    @extend .btn-green;
    color: $white !important;
    &:active,
    &:hover {
        background-color: darken($green, 15%);
        border-color: darken($green, 15%);
    }
}
.btn-blog {
    @extend .btn-secondary;
    color: $white !important;
    &:active,
    &:hover {
        background-color: darken($secondary, 15%);
        border-color: darken($secondary, 15%);
    }
}

.btn-notizie {
    color: $white !important;
    background-color: $primary;
    border-color: $primary;
    &:active,
    &:hover {
        background-color: darken($primary, 15%);;
        border-color: darken($primary, 15%);;
    }
}

.btn-storie {
    color: $white !important;
    background-color: $yellow;
    border-color: $yellow;
    &:active,
    &:hover {
        background-color: darken($yellow, 15%);
        border-color: darken($yellow, 15%);
    }
}

.text-incontri {
    color: $green;
}
.text-blog {
    color: $secondary;
}
.text-notizie {
    color: $primary;
}
.text-storie {
    color: $yellow;
}