#site-slider-carousel {
    .carousel-item, img {
        height: 100vh;
    }
    .carousel-media {
        object-fit: cover;
        // object-position: top;
    }
    .container {
        position: relative;
        padding-bottom: 0px;
    }
    .carousel-caption {
        left: 0;
        right: 0;
        padding: 0px 15px;
    }
    .carousel-caption {
        h5 {
            font-size: 0.875rem;
            font-weight: 700;
            padding: .5rem 1rem;
            background-color: $white;
            color: $gray-900;
            display: inline-block;
            margin-bottom: 1.25rem;
            line-height: 1.5 !important;
        }
        .meta-link--anchor {
            font-size: 0.875rem;
            font-weight: 700;
            padding: .5rem 1rem;
            display: inline-block;
            margin-bottom: 1.25rem;
            color: $white;
            &:hover,
            &:active,
            &:focus {
                background-color: $primary;
                color: $white;
            }
        }
        h3, .h3,
        h2, .h2 {
            font-size: 3.25rem;
            font-weight: 500;
            margin-top: 0px;
            margin-bottom: 1.25rem;
            text-shadow: 0px 0px 15px rgba(0,0,0,.35);
            color: $white;
            text-transform: uppercase;
        }
        p {         
            margin-bottom: 1.25rem;
        }
        padding-bottom: 5.625rem;
    }

    @include media-breakpoint-down(sm){
        .carousel-caption {    
            // padding-bottom: 1.875rem;            
            padding-bottom: 50px;
            h3, .h3,
            h2, .h2 {
                font-size: 2rem;
            }
        }
    }
}

.site-slider {
    &__scrolldown {
        position: absolute;
        color: #FFFFFF;
        bottom: 20px;
        left: 20px;
        z-index: 2;
        text-shadow: 0 0 15px rgba(0,0,0,.35);
            
        width: calc(100% - 40px);
        text-align: center;
        // @include media-breakpoint-down(md){
        //     bottom: -25px;
        // }
        &__icon {
            display: inline-block;
            margin-right: 5px;
        }
        &__text {
            display: inline-block;
        }
    }
}

// Sistemo posizione delle frecce di navigazione.
@each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        #site-slider-carousel {
            .carousel-control-next, .carousel-control-prev {
                width: calc((100vw - #{$container-max-width})/2);
            }
        }   
    }
}