body {
    &.main-nav-open {
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
    }
}
.sidebar-menu {
    &-wrapper {
        display: block;
        transform: translate3d(100%,0,0);
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1031;
        transition: transform 0.8s ease;
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            @include media-breakpoint-up(sm){
                width: 80%;
            }
            @include media-breakpoint-up(md){
                width: 70%;
            }
        }
        .main-nav-open & {
            transform: translate3d(0,0,0);
            transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }
        ::-webkit-scrollbar-track {
            background: rgba(65, 65, 65, 0.3);
        }
        ::-webkit-scrollbar-thumb {
            background-color: #414141;
        }
    }
    &-container {
        @extend .container;
        height: 100%;
        &__inner {
            @extend .row;
            height: 100%;
            justify-content: flex-end;
            &__col {
                height: 100%;
                @extend .col-12, .col-sm-10, .col-md-8;
            }
        }
    }
    &-content {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
    }
    &-header {
        overflow: visible;
        text-align: right;
        height: 101px;
        padding-top: 40px;
        padding-bottom: 8px;
        .navbar-fixed & {
            padding-top: 12px;
            padding-bottom: 12px;
            height: 77px;    
        }
    }
    &-body {
        overflow: auto;
        overflow-x: visible;
        overflow-y: auto;
        flex-basis: 100%;
        flex-basis: auto;
        // height: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-between;
    }
    &-footer {
        overflow: visible;
    }

    
}