// .site-navbar {
//     @extend .navbar, .navbar-dark, .fixed-top;
//     // background-color: $secondary;
//     // background-color: $black;
//     padding-left: 0;
//     padding-right: 0;
//     padding-top: 40px;
//     padding-bottom: 8px;
//     transition: padding 0.5s ease;
//     .navbar-fixed & {
//         padding-top: 12px;
//         padding-bottom: 12px;    
//         background-color: $black;
//         color: #999;
//     }
//     > .container {
//         align-items: flex-start;
//     }
// }

// .main-nav-open .site-navbar .navbar-toggler-label > .navbar-toggler-label__text {
//     transform: translateY(100%);
//     transition: transform 0.15s linear, color 0.15s linear;
//     color: rgba(255, 255, 255, 0);
// }
// .site-navbar .navbar-toggler-label > .navbar-toggler-label__text {
//     display: inline-block;
//     transition: transform 0.3s ease-out, color 0.3s ease-out;
// }

#wrapper-navbar{
    width: 100%;
    z-index: 3;
}

#wrapper-navbar.animation.fixed-top {
    top: -$navbar-height;
}

.navbar-toggler {
    color: $primary;
    transition: $custom-link-transition-out, top 0.5s ease;
    .site-navbar & {
        color: $white;
    }
    &:hover {
        color: $secondary;
        transition: $custom-link-transition-in, top 0.5s ease;
    }
    &-hamburger {
        display: inline-block;
        vertical-align: middle;
        // padding: 0.8em;
        width: 26px;
        box-sizing: content-box;
        // transition: $custom-link-transition-out;
        // &:hover {
        //     color: $gray-500;
        //     transition: $custom-link-transition-in;
        // }
        > svg {
            width: 26px;
        }
    }
    &-close {
        display: inline-block;
        vertical-align: middle;
        padding: 0.8em;
        width: 26px;
        box-sizing: content-box;
    }
    &-label {
        display: inline-block;
        overflow: hidden;
        text-transform: uppercase;
        margin-right: 1.8727272em;
        margin-left: 3em;
        &__text {
            display: none;
            transition: transform 0.3s ease-out 0.05s;
            transform: translateY(0);
            @include media-breakpoint-up(sm) {
                display: inline-block;
            }
            .main-nav-open .site-navbar & {
                transform: translateY(100%);
                transition: transform 0.15s linear;
            }
            .sidebar-menu-wrapper & {
                transform: translateY(100%);
                transition: transform 0.15s linear;
            }
            .main-nav-open .sidebar-menu-wrapper & {
                transform: translateY(0);
                transition: transform 0.3s ease-out 0.1s;
            }
        }
    }
    &.open-menu,
    &.close-menu,
    &.toggle-menu {
        position: relative;
        .navbar-fixed & {
            top: 8px;
        }
    }
    &.open-menu,
    &.toggle-menu {
        svg {
            opacity: 1;
            transition: transform 0.2s linear 0.1s;
            .main-nav-open & {
                opacity: 1;
            }
        }
        &:hover {
            svg {
                > path {
                    transition: transform 0.1s ease-out;
                    &.line-1 {
                        transform: translateY(2px);
                    }
                    &.line-2 {
                        transform: translateY(1px);
                    }
                }
            }
        }
        svg {
            > path {
                &.line-1 {
                    .main-nav-open & {
                        transform: translateY(100%);
                    }
                }
                &.line-2 {
                    .main-nav-open & {
                        transform: translateY(100%);
                    }
                }
            }
        }
    }
    &.close-menu {
        svg {
            transform: scaleY(0);
            transition: transform 0.5s ease-out;
            .main-nav-open & {
                transform: scaleY(1);
            }
        }
    }
}

.bg-primary,
.bg-page{
    & .navbar-toggler {
        color: $white;
    }
}
.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    // svg {
    //     > g {
    //         &#logo-text {
    //             > path,
    //             > polygon {
    //                 transition: fill 0.5s ease;
    //                 .navbar-fixed & {
    //                     fill: transparent;
    //                 }
    //             }
    //         }
    //     }
    // }
}

.main-logo-img {
    height: 53px;
}

// .entry-header-sizer{
//     padding-top: 4rem;
// }


.navbar-brand{
    img{
        height: 100%;
    }
}
