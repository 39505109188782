/* credits */
.credits-info {
    background-color: $credits-info-background-color;
    color: $credits-info-base-color;
    text-align: center;
    padding-top: $credits-info-wrapper-padding-top;
    padding-bottom: $credits-info-wrapper-padding-bottom;
    font-size: $credits-info-base-font-size !important;
    font-family: $credits-info-base-font;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    
    .container, 
    .site-footer .container { 
        padding-bottom: 0px; 
    }
    svg {
        vertical-align: baseline; 
        &#purelab_leaf { 
            margin: 0px 3px -3px; 
        }
        @if $credits-info-with-hosting {
            &#rocket_host { 
                margin: 0px 3px -6px; 
            }
        }
    }
    a { 
        color: $credits-info-base-color !important; 
        text-decoration: none !important;
        font-weight: 400 !important;
        &:hover {
            color: $credits-info-hover-color !important; 
        }
    }
    #cdd {
        > div { 
            display:inline-block; 
            margin: 0px 10px 3px 10px;
            &:last-child { 
                margin-right:0px; 
                @media only screen and (max-width : $credits-info-mobile-breakpoint) { 
                    margin-right:10px;
                }
            }
            &:first-child { 
                margin-left:0px; 
                @media only screen and (max-width : $credits-info-mobile-breakpoint) {
                    margin-left:10px;
                    @if $credits-info-with-hosting {
                        margin-bottom:10px;
                    }
                }
            }
            > span,
            span,
            a {
                color:$credits-info-base-color !important; 
                transition: $credits-info-transition-out;
            }
            svg {
                path {
                    transition: $credits-info-transition-out;
                }
            }
            &:hover {
                color: $credits-info-hover-color !important;
                > span,
                span,
                a {
                    color: $credits-info-hover-color !important;
                    text-decoration: none; 
                    transition: $credits-info-transition-in;
                }
                svg {
                    &#purelab_leaf {
                        path {
                            fill:$credits-info-hover-color !important;
                        }
                    }
                    @if $credits-info-with-hosting {
                        &#rocket_host {
                            path { 
                                fill:$credits-info-hover-color !important;
                            }
                        }
                    }
                }
            }
        }
    } 
    @media only screen and (max-width : $credits-info-mobile-breakpoint) { 
        text-align: center;
    }
}
