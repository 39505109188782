.wrapper {
    &-cta {
        &--inverse {
            color: $white;
        }
        .embed-responsive {
            position: relative;
            background-color: $white;
            &:hover,
            &:active,
            &:active {
                background-color: $primary;
            }
            // img {
            //     mix-blend-mode: multiply;
            // }
            .card-link {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                > .overlay {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: block;
                    background-color: $primary;
                    mix-blend-mode: multiply;
                    opacity: 0;
                    z-index: 2;
                    transition: opacity 0.35s ease;
                }
                &:hover {
                    > .overlay {
                        opacity: 0.4;
                        transition: opacity 0.15s linear;
                    }
                }
            }
        }
        .card {
            @extend .h-100;
            @extend .border-0;
            @extend .text-center;
        }
        .card-title {
            @extend .w-100;
            @extend .rounded-0;
            display: inline-block;
            font-size: 2.25rem;
            font-weight: 500;
            line-height: 1;
            padding: 1rem;
            background-color: $primary;
            text-transform: uppercase;
            max-width: 350px;
            color: $white;

            margin: auto;
            position: absolute;
            z-index: 5;
            top: calc(50% - (68px / 2 ));
            right: 0;
            left: 0;
            &-wrapper {
                position: absolute;
                width: 100%;

            }
        }
        .card-text {
            padding-left: 1rem;
        }
        .card-body {
            padding-bottom: 0px;
            p {
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
        .card-image-wrapper {
            padding-bottom: 30px;
        }
        .col-lg-4,
        .col-md-4 {
            .card-image-wrapper {
                padding-bottom: 30px;
            }   
            .card-title {
                font-size: 20px;
            }
        }

        img {
            @extend .rounded-0;
            object-fit: cover;
            z-index: 1;
        }
            
        @include media-breakpoint-down(sm){
            .row {
                > div {
                    margin-bottom: 1.875rem;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
            .card-text {
                padding-left: 0px;
            }
        }
    }
}