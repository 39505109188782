
.entry-header-sizer {
    min-height: 560px;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
    position: relative;
    webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    @include media-breakpoint-down(md){
        min-height: 350px;
    }
    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        mix-blend-mode: multiply;
        background-color: rgba(40, 40, 40, 0.3);
    }
    .entry-header {
        position: relative;
        z-index: 1;
        max-width: none;
        @include media-breakpoint-up(md){
            max-width: 60%;
        }
        @include media-breakpoint-up(lg){
            max-width: 40%;
        }

        h1, .h1,
        h3, .h3,
        h2, .h2,
        p {
            text-shadow: 0px 0px 15px rgba(0,0,0,.35);
        }
        .btn {
            @extend .shadow-sm;
        }
    }

    &.entry-header-sizer--nocover {
        padding-top: 6.25rem;
        min-height: 1px;
        &:before {
            display: none;
        }
        .entry-header {
            color: $gray-800;
            padding-top: 3.75rem;
            padding-bottom: 3.75rem;
            h1, .h1,
            h3, .h3,
            h2, .h2, p {
                color: $gray-800;  
                text-shadow: none;    
                &:last-child {
                    margin-bottom: 0px;
                }         
            }
            .btn {
                @extend .shadow-sm;
            }
        }
        @include media-breakpoint-down(sm){
            .entry-header {
                padding-top: 1.875rem;
                padding-bottom: 1.875rem;
            }
        }
    }
}
.entry-meta {
    margin-bottom: 3.75rem;
    @include media-breakpoint-down(sm){
        margin-bottom: 1.875rem;
	}
}
// .entry-footer {
//     margin-top: calc( 3.75rem - 10px );
//     @include media-breakpoint-down(sm){
//         margin-top: calc( 1.875rem - 10px );
// 	}
// }
.entry-text {
    > p:first-child {
        font-size: 130%;
        margin-bottom: 3.75rem;
        font-weight: normal;
        text-transform: uppercase;
        b, strong {
            font-weight: normal;
        }
    }
    > h2:first-child,
    > .h2:first-child,
    > h3:first-child,
    > .h3:first-child {
        font-size: 1.3125rem;
        margin-bottom: 3.75rem;
        text-transform: uppercase;
    }
    > *:last-child {
        margin-bottom: 0px;
    }
    * + h2,
    * + .h2,
    * + h3,
    * + .h3{
        margin-top: 3.75rem;
    }
    .wp-block-buttons + * {
        margin-top: 1rem;
    }
    @include media-breakpoint-down(sm){
        > p:first-child {
            margin-bottom: 1.875rem;       
        }
        > h2:first-child,
        > .h2:first-child,
        > h3:first-child,
        > .h3:first-child {
            margin-bottom: 1.875rem;
        }
            
        * + h2,
        * + .h2,
        * + h3,
        * + .h3{
            margin-top: 1.875rem;
        }
	}
}
.entry-header {
    color: $white;
    font-size: 1rem;
    max-width: 50%;
    h5 {
        font-size: .875rem;
        font-weight: 500;
        padding: .5rem 1rem;
        background-color: $white;
        color: $gray-800;
        display: inline-block;
        margin-bottom: 1.25rem;
        font-weight: 500;
    }
    h1 {
        color: $white;
        font-size: 2.5rem;
        font-weight: 500;
        margin-bottom: 1.2rem;
    }
    p {
        &:last-child {
            margin-bottom: 0px;
        }
        a {
            color: $white;
            &:hover,
            &:active {
                color: $gray-500;
            }
        }
    }
    &__meta {
        a {
            padding: .5rem 1rem;
            display: inline-block;
            margin-bottom: 0.625rem;
            margin-right: 0.625rem;
            padding: 0.4375rem 1.25rem !important;
            line-height: 1.5;
        }
    }

    @include media-breakpoint-down(sm){
        h1 {
            font-size: 2rem;
            &:last-child {
                margin-bottom: 0px;
            }
        }
	}
}

.sidebar--sticky {
    .position-sticky {
        top: 65px;
    }
}
.share-icons {
    & a {
        margin-bottom: 0.625rem;
        font-size: 1.9rem;
        color: $primary;
        display: inline-block;
        &:hover,
        &:focus,
        &:active {
            color: $secondary !important;
        }    
    }
    span {
        text-transform: uppercase;
    }
    @include media-breakpoint-down(sm){
        display: inline-block !important;
        margin-top: 1.875rem;
        span {
            font-weight: 500;
            font-size: 1.5rem;
            color: $primary;
            width: 100%;
            display: inline-block;
            margin-bottom: 1.25rem;
        }
        a {
            margin-right: 1.25rem;
        }
	}
}