$colors: (
	'primary' 		: $primary,
	'secondary'     : $secondary,
	'green' 		: $green,
	'yellow'		: $yellow,
	'white' 		: $white,
	'gray-100' 		: $gray-100,
	'gray-500' 		: $gray-500,
	'gray-600' 		: $gray-600,
	'gray-800' 		: $gray-800,
	'gray-900' 		: $gray-900,
	'black' 		: $black,
);


.wp-block-button__link {
    @extend .btn;
    @extend .btn-primary;   

    @each $name, $color in $colors {
        &.has-#{$name}-color {
            color: $color;
        }
    
        &.has-#{$name}-background-color {
            background-color: $color;
            border-color: $color;
        }

		&.has-#{$name}-color:hover,
		&.has-#{$name}-color:focus {
			color: $color;
		}

		&.has-#{$name}-background-color:hover,
		&.has-#{$name}-background-color:focus {
			background-color: darken( $color, 20% );
            border-color: darken( $color, 20% );
		}
    }
    
    &.has-gray-800-background-color {
        color: $white !important;
        background-color: $gray-800;
        border-color: $gray-800;
        &.has-gray-800-background-color:hover,
		&.has-gray-800-background-color:focus {
            color: $primary !important;
			background-color: $white;
            border-color: $white;
		}
    }
}

@each $name, $color in $colors {
	.has-#{$name}-color {
		color: $color;
	}

	.has-#{$name}-background-color {
		background-color: $color;
		border-color: $color;
	}

	.outer-row.has-background.has-#{$name}-background-color {
		&:before {
			background-color: $color;
		}
	}

	a.has-#{$name}-color:hover,
	a.has-#{$name}-color:focus {
		color: $color;
	}

	a.has-#{$name}-background-color:hover,
	a.has-#{$name}-background-color:focus {
		background-color: darken( $color, 20% );
		border-color: darken( $color, 20% );
	}
}


.wp-block-button__link {
	&.has-secondary-background-color {
		color: $white;
		&:hover,
		&:focus {
			color: $white;
			background-color: $primary;
			border-color: $primary;
		}
	}
	
	&.has-primary-background-color {
		color: $white;
		&:hover,
		&:focus {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
	&.has-green-background-color {
		color: $white;
		&:hover,
		&:focus {
			color: $white;
			background-color: $primary;
			border-color: $primary;
		}
	}
}

.wp-block-group.has-background {
	p a {
		color: $white;
		&:hover,
		&:active,
		&:focus {
			color: $gray-100;
		}
	}
}

.btn-secondary {
    color: $white;
	&:hover,
	&:active,
	&:focus {
		color: $white;
	}
}   

.content-primary,
.content-secondary,
.wrapper-testoimmagini-inverse, 
.wrapper-testoimmagini-layout_02 {
	.wp-block-button__link {
		&.has-primary-background-color,
		&.has-secondary-background-color,
		&.has-green-background-color,
		&.has-yellow-background-color {
			&:hover,
			&:focus {
				background-color: $white;
                border-color: $white;
                color: $primary;
			}
		}
	}
}

// .btn--area-adulti { 
// 	// &.dropdown-toggle,
// 	&:hover,
// 	&:active,
// 	&:focus {        
// 		background-color: darken( #fecc00, 20% );
// 		border-color:  darken( #fecc00, 20% );
// 		color: #fff;
// 	}  
// }