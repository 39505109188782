#wrapper-prefooter-full {
    background-color: $primary;
    color: $white;
    font-size: 0.875rem;
    padding-top: 4.0625rem;
    padding-bottom: clac(4.0625rem -  3.5rem);
    // border-top: 1px solid $gray-100;
    @extend .shadow-sm;
    a {
        color: $white;
        &:hover,
        &:focus,
        &:active {
            color: $secondary;
        }        
        &.social-ico {
            // margin-top: 2rem;
            margin-bottom: 2rem;
            font-size: 1.9rem;
            color: $primary;
            display: inline-block;
            &:hover,
            &:focus,
            &:active {
                color: $secondary !important;
            }    
        }
        &.btn-primary {
            color: #FFFFFF;
            &:hover,
            &:focus,
            &:active {
                color: #FFFFFF !important;
                background-color: $secondary;
                border-color: $secondary;
            } 
        }
    }
    .widget-title {
        font-size: 1rem;
        color: $white;
        margin-bottom: 1rem;
        // font-weight: 700;
    }
    .menu {
        padding: 0px;
        margin-bottom: 0px;
        list-style: none;
        li+li {
            margin-top: .5rem;

            // &:last-child {
            //     a {
            //         margin-bottom: 0px;
            //     }
            // }
            // a {
            //     margin-bottom: 0.625rem;
            //     display: inline-block;
            // }
        }

        &-social-container{
            & .menu{
                display: flex;
                li+li{
                    margin-top: 0;
                    margin-left: 1.5rem;
                }
            }
        }
        // &-primary-container{
        //     & .menu{
        //         li{
        //             min-width: 50%;
        //         }
        //     }
        // }
    }
    .btn-primary {
        &:hover,
        &:active,
        &:focus {  
            color: $white !important;      
            background-color: $white;
            border-color: $white;
        }
    }
    .btn-secondary {
        color: #FFF;
        &:hover,
        &:active,
        &:focus {  
            color: #FFF !important;
            background-color: $primary;
            border-color: $primary;
        }
    }

    // .widget_media_image svg {
    //     max-width: 100%;
    // }
    
    .last-col{
        & .footer-widget:first-child .widget-title{
            color: $white;
        }
        & .widget-title{
            color: $primary;
        }
    }

    @include media-breakpoint-down(sm){
        padding-top: 1.875rem;
        padding-bottom: 0.325rem;
        // .footer-brand img, .footer-brand svg {
        //     margin-bottom: 1.25rem;
        // }
        .footer-widget {
            margin-top: 0px;
        }
        .container {
            > .row {
                > div {
                    margin-top: 0px !important;
                    margin-bottom: 1.25rem !important;
                    &:empty() {
                        display: none;
                    }
                }
            }
        }
        a {
            &.social-ico {
                margin-bottom: 1.25rem;
            }
        }
	}
}
.footer-brand { 
    // a {
    //     color: $primary;
    //     &:hover { 
    //         color: $primary !important; 
    //     }
    // }
    p {
        margin: 0px;
    }
    svg, img {
        max-width: 100px;
        height: auto;
        // margin-bottom: 2.5rem;
        // margin-bottom: 1.25rem;
        margin-right: 0px;
    }
}
// .row { 
//     &--line {
//         position: relative;
//         &::before {
//             content: "";
//             width: 1px;
//             height: calc(100% - 3.5rem);
//             position: absolute;
//             top: 0px;
//             left: 30.33333%;
//             background-color: $secondary;
//             display: block;
//         }
//     }
// }
// .col {
//     &-endline {
//         position: relative;
//         &:before {
//             @include media-breakpoint-up( md ){
//                 content: "";
//                 width: 1px;
//                 // height: calc(100% - 3.5rem);
//                 height: calc(100% - 1.5rem);
//                 position: absolute;
//                 top: 0px;
//                 right: 0;
//                 background-color: $secondary;
//                 display: block;
//             }
//         }
//     }
// }
.footer {
    .sub-menu {
        padding-left: 0;
        margin-bottom: .625rem;
        list-style-type: none;
    }
}

.entry-content a.social-ico {
    // margin-top: 2rem;
    margin-bottom: 0px;
    font-size: 1.9rem;
    color: $primary;
    display: inline-block;
    &:hover,
    &:focus,
    &:active {
        color: $secondary !important;
    }    
    
    @include media-breakpoint-down(sm){
        margin-bottom: 0px;
	}
}