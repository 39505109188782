%content-dark{
  background-color: $primary;
  & h1,h2,h3,h4,h5,h6,p,small{
    color: $white;
  }

  & .btn{
    &:hover,
    &:active,
    &:focus{
      background-color: $white;
      color: $primary;
    }
  }
}

.content-dark{
  @extend %content-dark;
}

%content-stampa{
  background-color: $stampa-primary;
  & h1,h2,h3,h4{
    // color: $stampa-secondary;
    color: $white;
  }
  & h5,h6,p,small{
    color: $white;
  }

  & .btn{
    &:hover,
    &:active,
    &:focus{
      background-color: $white;
      color: $primary;
    }
  }
}

.content-stampa{
  @extend %content-stampa;
}

%content-ins{
  background-color: $ins-primary;
  & h1,h2,h3,h4{
    // color: $ins-secondary;
    color: $white;
  }
  & h5,h6,p,small{
    // color: $ins-secondary;
    color: $white;
  }

  & .btn{
    &:hover,
    &:active,
    &:focus{
      background-color: $white;
      color: $primary;
    }
  }
}

.content-ins{
  @extend %content-ins;
}

%content-lum{
  background-color: $lum-primary;
  & h1,h2,h3,h4{
    color: $lum-secondary;
  }
  & h5,h6,p,small{
    color: $lum-secondary;
    // color: $white;
  }

  & .btn{
    &:hover,
    &:active,
    &:focus{
      background-color: $white;
      color: $primary;
    }
  }
}

.content-lum{
  @extend %content-lum;
}

%content-pro{
  background-color: $pro-primary;
  & h1,h2,h3,h4{
    // color: $pro-secondary;
    color: $white;
  }
  & h5,h6,p,small{
    // color: $pro-secondary;
    color: $white;
  }

  & .btn{
    &:hover,
    &:active,
    &:focus{
      background-color: $white;
      color: $primary;
    }
  }
}

.content-pro{
  @extend %content-pro;
}

%content-alle{
  background-color: $alle-secondary;
  & h1,h2,h3,h4{
    // color: $alle-primary;
    color: $white;
  }
  & h5,h6,p,small{
    // color: $alle-secondary;
    color: $white;
  }

  & .btn{
    &:hover,
    &:active,
    &:focus{
      background-color: $white;
      color: $primary;
    }
  }
}

.content-alle{
  @extend %content-alle;
}
