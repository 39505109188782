// Stile per il blocco FAQ di Yoast https://yoast.com/wordpress/plugins/seo/faq-schema-content-block/.
// Utilizzando questo blocco nei risultati di Google verranno indicate la FAQ correttamente.

// Effetto "collapse", vedi anche il rispettivo JS in src/js/faq.js.
// Base.
.schema-faq-question {
    cursor: pointer;
    &::before {
        width: 16px;
        height: 20px;
        display: inline-block;
        content: "+";
        margin-right: 5px;
        vertical-align: top;
    }
    &.collapse {
        &::before {
            content: "-";
        }
    }
    &:hover{
        opacity: 0.8;
    }
}
.schema-faq-answer {
    display: none;
    padding-left: 1rem;
    &.default{
        display: block;
    }
}

.schema-faq-section {
    // background-color: $gray-100;
    color: $gray-800;
    border-bottom: 1px solid $gray-800;
    padding-bottom:0.625em;
    margin-bottom:0.625em;
    font-size: 1rem;
    // padding-left: 1rem;
    &:last-child {
        margin-bottom: 0px;
        padding-bottom: 0px;
        border-bottom: 0px;
    }
    .schema-faq-question {
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        // margin-bottom: 0.3125rem;
        display: inline-block;
        width: 100%;
        position: relative;
        color: $primary;
        font-weight: 500;
        font-size: 1.125rem;
        text-transform: uppercase;
        padding-right: 50px;
        &::before {
            content: none;
        }
        &::after {
            content: "";
            -webkit-mask: url(../img/svg/angle-down-solid.svg) no-repeat 50% 50%;
            mask: url(../img/svg/angle-down-solid.svg) no-repeat 50% 50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            width: 16px;
            height: 27px;
            right: 1rem;
            top: 0.3125rem;
            display: block;
            position: absolute;
            background-color: $gray-600;
        }
        &.collapse {
            &::after {
                transform: rotate(180deg);
                background-color: $primary;
            }
        }   
        &.collapse,
        &:hover{
            opacity: 1;
            color: $primary;
            &::after {
                background-color: $primary;
            }
        }
    }
    .schema-faq-answer {
        padding-bottom: 0.3125rem;
        padding-left: 0px;
    }
    &:last-child(),
    p:last-child() {
        margin-bottom: 0px;
    }
}