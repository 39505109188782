#wrapper-footer-full { 
    background-color: $primary;
    color: $white;
    font-size: 0.875rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    a {
        color: $white;
        &:not(.btn) {
            line-height: 48px;
            font-weight: 700;
        }
        &:hover {
            color: $primary;
        }
    }
    .widget-title {
        margin-bottom: 1rem;
        font-size: 1.875rem;
        font-weight: 500;
        color: $white;
    }
    .row {
        margin-left: -3.125rem;
        margin-right: -3.125rem;
        > div {
            padding-top: 2.1875rem;
            padding-bottom: 2.1875rem;
            padding-left: 3.125rem;
            padding-right: 3.125rem;
            border-right: 1px solid $gray-600;
            &:last-child {
                border-right: 0px;
            }
        }
    }
    .btn-primary {
        &:hover,
        &:active,
        &:focus {  
            color: $gray-800 !important;      
            background-color: $white;
            border-color: $white;
        }
    }
}
.social-ico {
    color: $primary;
    &:last-child {
        margin-right: 0px;
    }
    font-size: 1.25rem;
    margin-right: 1.25rem;
}

.footer-widget {
    margin-bottom: 40px;
}

.navbar-footer img{
    height: 50px;
    margin-bottom: 1.5rem;
}