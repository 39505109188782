.wrapper { 
    &--breadcrumbs {
        border-bottom: 1px solid $gray-100;
        border-top: 1px solid $gray-100;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: 80%;
        color: $gray-600;
        #breadcrumbs {            
            margin-right: -5px;
            margin-left: -5px;
        }
        a, strong {
            color: $gray-600;
            padding-right: 5px;
            padding-left: 5px;
            // &:first-child {
            //     padding-left: 0px;
            // }
            // &:last-child {
            //     padding-right: 0px;
            // }
        }
        a {
            &:hover,
            &:focus,
            &:active {
                color: $primary;
            }
        }
    }
}