.widget {
    padding-bottom: 20px;
    font-size: 0.875rem;
    &:last-child() {
        padding-bottom: 0px;
    }
    &_category,
    &_tag_cloud {
        .widget-title {
            margin-bottom: 10px;
        }
    }
    &_category,
    &_archive {
        ul {
            list-style: none;
            padding: 0px;
        }
        li {
            padding: 0px;
        }
        a {
            color: $gray-800;
            &:hover,
            &:focus,
            &:active {
                color: $primary;
            }
        }
        .btn {
            margin: 10px 10px 0 0!important;
            padding: 7px 20px!important;
            position: relative;
            left: auto;
            bottom: auto;
            width: auto;
            min-width: 1px;
            color: $white;
            line-height: 1.5;
        }
    }
    &_tag_cloud {
        .tagcloud {
            a {
                background-color: transparent;
                border: 1px solid $primary;
                padding: 7px 20px !important;
                text-transform: uppercase;
                color: $primary;
                display: inline-block;
                margin: 10px 10px 0px 0px !important;
                vertical-align: top;
                font-size: 0.875rem !important;
                font-weight: 700;

                &:hover,
                &:focus,
                &:active {
                    background-color: $primary;
                    color: #FFFFFF;
                }
            }
        }
    }
    &_search {
        form {
            @extend .border;
            border-color: $secondary !important;
            a {
                display: flex;
                align-items: center;
            }
        }
    }
    &_viewed {
        .row--post-recenti {
            padding-bottom: 0px;
        }
    }
}
.entry-footer {
    .tagcloud {
        a {
            background-color: transparent;
            border: 1px solid $primary;
            padding: 7px 20px !important;
            text-transform: uppercase;
            color: $primary;
            display: inline-block;
            margin: 10px 10px 0px 0px !important;
            vertical-align: top;
            font-size: 0.875rem !important;
            font-weight: 700;
    
            &:hover,
            &:focus,
            &:active {
                background-color: $primary;
                color: #FFFFFF;
            }
        }
    }
}