// a, a:hover,
.dropdown-menu, .dropdown-menu.show,
.embed-responsive, .embed-responsive:hover,
.row--aree-interventi .card .position-relative, .row--aree-interventi .card .position-relative:hover, 
.row--post-recenti .card .position-relative, .row--post-recenti .card .position-relative:hover, 
.row--post-sostegni .card .position-relative, .row--post-sostegni .card .position-relative:hover,
.btn-primary--animation::after, .btn-primary--animation:hover::after,
.btn-primary--animation::before, .btn-primary--animation:hover::before,
.row--aree-interventi .card.shadow, .row--aree-interventi .card.shadow:hover,
.schema-faq-section .schema-faq-question::after, .schema-faq-section .schema-faq-question.collapse, .schema-faq-section .schema-faq-question:hover,
#wrapper-navbar.fixed-top, #wrapper-navbar.animation.fixed-top,
.widget_tag_cloud .tagcloud a, .widget_tag_cloud .tagcloud a:hover,
.row--aree-interventi a .card-title, .row--post-recenti a .card-title, .row--post-sostegni a .card-title, .row--aree-interventi a:hover .card-title, .row--post-recenti a:hover .card-title, .row--post-sostegni a:hover .card-title {
	transition: $transition-base;
}

// #main-menu.main-menu--search--open, #main-menu,
// body.search--open .menu-item-type-custom--search .animated, body .menu-item-type-custom--search .animated {
// 	-webkit-transition: all 0.5s ease-in-out;
// 	-moz-transition: all 0.5s ease-in-out;
// 	-o-transition: all 0.5s ease-in-out;
// 	transition: all 0.5s ease-in-out;
// }

@-webkit-keyframes moveclouds {
    0% {margin-left: 1000px;}
    100% {margin-left: -1000px;}
}
@-moz-keyframes moveclouds {
    0% {margin-left: 1000px;}
    100% {margin-left: -1000px;}
}
@-o-keyframes moveclouds {
    0% {margin-left: 1000px;}
    100% {margin-left: -1000px;}
}
@keyframes moveclouds {
    0% {margin-left: 1000px;}
    100% {margin-left: -1000px;}
}

.animate,
.animate-img{
	position: relative;
    animation-duration: .5s;
    animation-timing-function: cubic-bezier(0.02, 0.35, 0.25, 1);
    // animation-timing-function: cubic-bezier(.23,.29,.13,.86);
    animation-fill-mode: forwards;

	&-delay{
		@for $i from 1 through 5 {
			&-#{$i}{
				animation-delay: $i*100ms;
			}
		}
	}

	&-bg{
		position: absolute;
		display: block;
		top: 0;
		width: 100%;
		height: 100%;
		animation-duration: .7s;
		animation-timing-function: ease-in-out;
		opacity: 1;
		background: $secondary;
		
		&--left{
			left: 0;
		}
		&--right{
			right: 0;
		}
	}

	&-start{
		&.animate-right{
			animation-name: slideRight;
	    }

		&.animate-left{
			animation-name: slideLeft;
	    }

		&.animate-up{
			animation-name: slideUp;
	    }
		
		&.animate-down{
			animation-name: slideDown;
	    }

		&.animate-bg--left,
		&.animate-bg--right{
			animation-name: slideBg;
		}
	}
	
	&-right{
        transform: translateX(-50px);
		opacity: 0;
	}
	&-left{
        transform: translateX(50px);
		opacity: 0;
	}
	&-up{
        transform: translateY(50px);
		opacity: 0;
	}
	&-down{
        transform: translateY(-50px);
		opacity: 0;
	}

}




@keyframes slideRight {
    to{
        opacity: 1;
        transform: translateX(0);
    }
    
}

@keyframes slideLeft {
    to{
        opacity: 1;
        transform: translateX(0);
    }
    
}

@keyframes slideUp{
    to{
        opacity: 1;
        transform: translateY(0);
    }
    
}

@keyframes slideDown{
    to{
        opacity: 1;
        transform: translateY(0);
    }
    
}


@keyframes slideBg {
  99% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}


.container-stampa{
	.animate-bg{
		background: $stampa-secondary;
	}
}
.container-ins{
	.animate-bg{
		background: $ins-secondary;
	}
}
.container-pro{
	.animate-bg{
		background: $pro-secondary;
	}
}
.container-lum{
	.animate-bg{
		background: $lum-secondary;
	}
}
.container-alle{
	.animate-bg{
		background: $alle-primary;
	}
}