.wrapper-testoimmagini {
    p {
        &:last-child {
            margin-bottom: 0rem;
        }
    }
    h3, h2, .h3, .h2 { 
        font-weight: 400;
        font-size: 2rem;
        @include media-breakpoint-up(lg){
            font-size: 40px;
        } 
    }
    &-layout_04 {
        margin-top: 4rem;
        background-color: $secondary;
        color: $white;
        text-shadow: 0px 0px 15px rgba(0,0,0,.35);
        h3, h2, .h3, .h2 { 
            color: $white; 
        }
        .row {
            min-height: 100vh;
        }
        .btn {
            @extend .shadow-sm;
            &:hover,
            &:active,
            &:focus {
                @extend .shadow;
            }
        }
    }
    &-layout_03 {
        background-color: $yellow;
        position: relative;
        background-repeat: no-repeat;
        background-position: right center;
        background-size:50% 100%;
        .row {        
            min-height: 50vh;
        }
        h3, h2, .h3, .h2 { color: $primary; }
        .img-outcontent {
            display: block;
            width: 55vw;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0px;
            right: 0%;
            background-color: $gray-800;
            background-position: center;
            background-size: cover;
            user-drag: none; 
            user-select: none;
            -moz-user-select: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }

        a:not(.btn) {
            color: $white;
            &:hover,
            &:active,
            &:focus {
                color: darken($white, 15);
            }
        }
        
        .btn {
            margin-top: 1.875rem;
        }
    }
    &-layout_02,
    &-inverse {
        background-color: $primary;
        color: $white;
        position: relative;
        background-repeat: no-repeat;
        background-position: left center;
        background-size:50% 100%;
        .row {        
            min-height: 50vh;
        }
        h3, h2, .h3, .h2 { color: $white; }
        // &::before {
        //     content: "";
        //     display: block;
        //     width: 50vw;
        //     height: 100%;
        //     object-fit: cover;
        //     position: absolute;
        //     top: 0px;
        //     right: 50vw;
        //     background-color: $gray-800;
        //     background-position: center;
        //     background-size: cover;
        // }
    
        .img-outcontent {
            display: block;
            width: 55vw;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0px;
            left: 0px;
            background-color: $secondary;
            background-position: center;
            background-size: cover;
            user-drag: none; 
            user-select: none;
            -moz-user-select: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }

        a:not(.btn) {
            color: $white;
            &:hover,
            &:active,
            &:focus {
                color: darken($white, 15);
            }
        }
    }

    &-layout_05{
        & .container{
            position: relative;
            &::before{
                @include media-breakpoint-up(md){
                    content: '';
                    position: absolute;
                    display: block;
                    background-color: $secondary;
                    width: 50vw;
                    height: 100%;
                    top: 0;
                    left: calc(-50vw + 15px);
                }
            }
            
            &.container-stampa{
                &::before{
                    @include media-breakpoint-up(md){
                        background-color: $stampa-secondary;
                    }
                }
                h3{
                    color: $stampa-secondary;
                }
            }
            &.container-ins{
                &::before{
                    @include media-breakpoint-up(md){
                        background-color: $ins-secondary;
                    }
                }
                h3{
                    color: $ins-secondary;
                }
            }
            &.container-pro{
                &::before{
                    @include media-breakpoint-up(md){
                        background-color: $pro-secondary;
                    }
                }
                h3{
                    color: $pro-secondary;
                }
            }
            &.container-lum{
                &::before{
                    @include media-breakpoint-up(md){
                        background-color: $lum-secondary;
                    }
                }
                h3{
                    color: $lum-secondary;
                }
            }
            &.container-alle{
                &::before{
                    @include media-breakpoint-up(md){
                        background-color: $alle-primary;
                    }
                }
                h3{
                    color: $alle-primary;
                }
            }
        }
    }

    &-layout_06{
        & .container{
            position: relative;
            &::before{
                @include media-breakpoint-up(md){
                    content: '';
                    position: absolute;
                    display: block;
                    background-color: $secondary;
                    width: 50vw;
                    height: 100%;
                    top: 0;
                    left: calc(100% - 15px)
                }
            }

            &.container-stampa{
                &::before{
                    @include media-breakpoint-up(md){
                        background-color: $stampa-secondary;
                    }
                }
                h3{
                    color: $stampa-secondary;
                }
            }
            &.container-ins{
                &::before{
                    @include media-breakpoint-up(md){
                        background-color: $ins-secondary;
                    }
                }
                h3{
                    color: $ins-secondary;
                }
            }
            &.container-pro{
                &::before{
                    @include media-breakpoint-up(md){
                        background-color: $pro-secondary;
                    }
                }
                h3{
                    color: $pro-secondary;
                }
            }
            &.container-lum{
                &::before{
                    @include media-breakpoint-up(md){
                        background-color: $lum-secondary;
                    }
                }
                h3{
                    color: $lum-secondary;
                }
            }
            &.container-alle{
                &::before{
                    @include media-breakpoint-up(md){
                        background-color: $alle-primary;
                    }
                }
                h3{
                    color: $alle-primary;
                }
            }
        }
    }
    &-layout_07,
    &-layout_08{
        margin-bottom: 4rem;
        & .container{
            position: relative;
            z-index: 2;
        }
    }

    & .btn {
        margin-top: 1.875rem;
    }


    @include media-breakpoint-down(sm){
        &-layout_03,
        &-layout_02,
        &-inverse {            
            .img-outcontent {                        
                position: relative;
                width: 100vw;
                height: 50vh;
                top: auto;
                right: auto;
                margin-bottom: 1.875rem;
                margin-top: -1.875rem
            }                
            .row {        
                min-height: 1px;
            }
        
            .btn {
                margin-top: 1.25rem;
            }
            .wpcf7 {
                .btn {
                    margin-top: 0px;
                }
            }
        }
    }
    @include media-breakpoint-down(xs) {
        h1, .h1, h2, .h2, h3, .h3 {
            word-break: break-word;
        }
    }
}