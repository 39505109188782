.container-row-citazionesfondo {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(45deg,rgba(40,40,40, 0.8),rgba(40,40,40, 0));
        mix-blend-mode: multiply;
    }
}
.container-citazionesfondo {
    font-size: 1rem;
    color: $white;
    font-style: normal;
    h1,h2,h3 {
        font-size: 2.5rem;
        color: $white;
        font-weight: 500;
    }
    .row {
        min-height: calc(100vh - 50px);
        padding-bottom: 6.25rem;
        padding-top: 6.25rem;
    }
    blockquote {
        line-height: 1.3;
        text-shadow: 0px 0px 15px rgba(0,0,0,.35);
        margin: 0px;
        // &::before {
        //     content: '';            
        //     width: 43px;
        //     height: 42px;
        //     display: block;
        //     background-image: url('../img/svg/quote_b.svg');
        //     background-repeat: no-repeat;
        //     background-position: center top;
        //     background-size: contain;
        //     margin-bottom: 1.25rem;
        // }
        p {
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
    cite {
        font-style: normal;
    }
    .btn {
        &-primary {
            &:hover,
            &:focus {
                color: $primary;
                background-color: #FFFFFF;
            }
        }
        &-secondary {
            &:hover,
            &:focus {
                color: $secondary;
                background-color: #FFFFFF;
            }
        }
    }
    
    @include media-breakpoint-down(sm){
        h1,h2,h3 {
            font-size: 1.5rem;
            display: inline-block;
            max-width: 70%;
        }
        .row {
            min-height: 100vh;
            padding-bottom: 1.875rem;
            padding-top: 1.875rem;
        }
	}
}

body.page-id-490 {
    .entry-header {
        &::before {
            content: '';            
            width: 43px;
            height: 42px;
            display: block;
            background-image: url('../img/svg/quote_b.svg');
            background-repeat: no-repeat;
            background-position: center top;
            background-size: contain;
            margin-bottom: 1.25rem;
        }
    }
}