.carosello-slider {
    &__wrapper {
        align-items: center;
    }
    &__slide {
        text-align: center;
        // @include media-breakpoint-up(md){
            
        // }
        // height: 100%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
    }
    &__col{
        @include media-breakpoint-up(md){
            margin-bottom: 30px;
        }
    }
    &__link {
        max-width: 100%;
        display: inline-block;
    }
    &__img {
        max-width: 100%;
        opacity: .5;
        filter: grayscale(100%);
        transition: $transition-base;
        
        &:hover{
            opacity: 1;
            filter: grayscale(0);
        }
    }
    &__testo {
        padding-bottom: 20px;
    }
    &.carosell-slider {
        &--loghi{
            .carosell-slider {
                &__link {

                }
                &__img {

                }
                &__testo {

                }
            }
        }
    }
}