.wrapper-lettere{
  
  & p,h1,h2,h3,h4,h5,h6{
    color: $white;
  }

  & .container{
    position: relative;
    z-index: 2;
  }

  & h3{
    @extend .h1;
    font-weight: 400;
  }

  &__wrapper{
    position: relative;
    display: flex;
    align-items: center;

    & img{
      position: relative;
      mix-blend-mode: luminosity;
      filter: grayscale(1);
      @include media-breakpoint-between(md,lg){
          max-height: 60%;
      }
    }
  }
  &__wrapperlabel-1{
    position: relative;
    flex-grow: 1;
    margin-left: -8.3333%;
    &::before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height:5px;
      width:100%;
      border:none;
      border-top:5px dotted $secondary;
      transform: rotate(90deg);
      transform-origin: 0;
      @include media-breakpoint-up(md){
        transform: none;
        width:150%;
      }
    }
  }
  &__wrapperlabel-2{
    position: relative;
    flex-grow: 1;
    margin-left: -16.6666%;
    &::before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      border:none;
      height:5px;
      width:100%;
      border-top: 5px dotted $secondary;
      transform: rotate(90deg);
      transform-origin: 0;
      @include media-breakpoint-up(md){
        transform: none;
        width:150%;
      }
    }
  }
  &__wrapperlabel-3{
    position: relative;
    flex-grow: 1;
    margin-left: -12%;
  }

  &__label{
    display: block;
    font-size: 20px;
    @include media-breakpoint-between(md,lg){
      font-size: 14px;
    }
    
    & strong{
      font-size: 30px;
      @include media-breakpoint-between(md,lg){
        font-size: 25px;
      }
    }
  }
}