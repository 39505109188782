.widgetarea {

}
.widget-item {
    .menu {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        > li.menu-item {
            font-size: 16px;
            line-height: 1.125em;
            margin-bottom: .3rem; 
        }
    }
    &-social {
        display: flex;
        flex-wrap: wrap;
        // margin-right: -13px;
        // margin-left: -13px;
        justify-content: flex-start;
        align-items: center;
        margin-top: 4em;
        @include media-breakpoint-up(lg) {
            justify-content: space-between;
        }
        > div {
            @extend .col-auto;
            padding-right: 13px;
            padding-left: 13px;
            &.widget {
                &_media_image {

                }
            }
        }
        .social-icon-link {
            transition: opacity 0.3s ease-in-out;
            &:hover {
                opacity: 0.5;
                transition: opacity 0.3s ease-in-out;
            }
        }
    }
}
.header-widget-title {
    text-transform: uppercase;
    // line-height: 1.18em;
    // margin-bottom: 1.745454em;
}
// .widget-title {
//     text-transform: uppercase;
//     line-height: 1.18em;
//     margin-bottom: 1.745454em;
// }