.wrapper-sticky {
    p {
        &:last-child {
            margin-bottom: 1.875rem;
        }
    }
    &-inverse {
        background-color: $primary;
        color: $white;
        position: relative;
        background-repeat: no-repeat;
        background-position: left center;
        background-size:50% 100%;
        .row {        
            min-height: 50vh;
        }
        h3 { color: $white }
        // &::before {
        //     content: "";
        //     display: block;
        //     width: 50vw;
        //     height: 100%;
        //     object-fit: cover;
        //     position: absolute;
        //     top: 0px;
        //     right: 50vw;
        //     background-color: $gray-800;
        //     background-position: center;
        //     background-size: cover;
        // }
    
        .img-outcontent {
            display: block;
            width: 50vw;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0px;
            right: 50%;
            background-color: $gray-800;
            background-position: center;
            background-size: cover;
            user-drag: none; 
            user-select: none;
            -moz-user-select: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }
    }
}