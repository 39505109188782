// @media only screen and (min-width : 1100px) {
//     #navbarNavDropdown {
//         display: -webkit-box!important;
//         display: -ms-flexbox!important;
//         display: flex!important;
//         -ms-flex-preferred-size: auto;
//         flex-basis: auto;
//     }
// }
@media only screen and (min-width : 1440px) {
    // .navbar-expand-md {
    //     .navbar-nav {
    //         .nav-link {
    //             padding-left: 1rem;
    //             padding-right: 1rem;
    //         }
    //     }
    // }
}
@media only screen and (min-width : 768px) {
    // .navbar-expand-md {
    //     .navbar-toggler {
    //         display: block !important;
    //     }
    // }
    // .navbar-nav--additional {
    //     // display: none !important;
    // }
}
@media only screen and (min-width : 1100px) {
    // .navbar-expand-md {
    //     .navbar-toggler {
    //         display: none !important;
    //     }
    // }
    // .navbar-nav--additional {
    //     display: flex !important;
    // }
    // #navbarNavDropdown {
    //     display: none;
        
    // }
}

/* Medium */	
@media only screen and (max-width : 1099px) {
    // .navbar-expand-md {
    //     // > .container,
    //     // > .container-fluid {
    //     //     -ms-flex-wrap: wrap;
    //     //     flex-wrap: wrap;
    //     // }
    //     #navbarNavDropdown {
    //         &.navbar-collapse {
    //             display: none !important;
    //             -ms-flex-preferred-size: 100%;
    //             flex-basis: 100%;
    //             &.show {
    //                 display: block !important;
    //             }
    //         }
    //         .navbar-nav {
    //             display: -webkit-box;
    //             display: -ms-flexbox;
    //             display: flex;
    //             -webkit-box-orient: vertical;
    //             -webkit-box-direction: normal;
    //             -ms-flex-direction: column;
    //             flex-direction: column;
    //         }
    //     }
    // }
}

/* Small */
// @media only screen and (max-width : 979px) {
@include media-breakpoint-down(md){
    // .navbar-brand img, .navbar-brand svg {
    //     max-width: 180px;
    // }
    // .navbar-expand-md .navbar-nav>.menu-item {
    //     margin-left: 10px;
    // }
    // .navbar-toggler, .navbar-toggler > * {
    //     outline: none !important;
    // }
    .entry-header {
        max-width: none;
    }
    // .navbar-expand-md .navbar-nav {
    //     // padding-top: 15px;
    // }
    // .navbar-expand-md .navbar-nav .nav-link {
    //     padding-left: 0px;
    //     padding-right: 0px;
    //     // font-size: 1.125rem;
    //     border-top: 1px solid $gray-100;
    //     &:first-child() {
    //         border-top: 0px;
    //     }
    // }
    #site-slider-carousel .carousel-item, #site-slider-carousel img {
        max-height: none;
        height: 100vh;
    }
    .carousel-control-next, .carousel-control-prev {
        display: none;
    }
    // .wrapper-testoimmagini {
    //     img {
    //         margin-top: 2.5rem;
    //     }
    // }
    .container-row-citazionesfondo {
        background-attachment: scroll;
        background-position: center right;
    }
    #wrapper-footer-full .row {
        margin-left: -15px;
        margin-right: -15px;
    }
    #wrapper-footer-full .row>div {
        padding: 0px 15px;
        margin-top: 2.5rem;
        &:first-child() {
            margin-top: 0px;
        }
    }
    .wp-block-columns {
        margin-bottom: 0px;
        margin-top: 0px;
        p:last-child() {
            margin-bottom: 0px;
        }
    }
    .wrapper-testoimmagini p:last-child {
        margin-bottom: 0px;
    }
    .filter-menu {
        margin-bottom: 1.875rem;
    }
    .wrapper-articoli-suggeriti {
        margin-bottom: 0px;
    }
    .wrapper-testoimmagini {
        p {
            word-wrap: break-word;
            white-space: break-spaces;
        }
    }
    .form-check-label {
        margin-top: 0px !important;
    }
}
/* Extra Small */
@media only screen and (max-width : 767px) {
    .wp-block-spacer {
        height: 1.875rem !important;
    }
    .wrapper--error-404 .error-404-big {
        font-size: 7rem;
    }
    .navbar-expand-md .navbar-nav>.menu-item {
        margin-left: 0px;
    }
    .entry-content .wpcf7 input[type=submit], 
    .wpcf7 .entry-content input[type=submit] {
        margin-top: 0px !important;
    }

    .dropdown-menu {
        padding: 0px;
        text-align: left;
        color: $gray-800;
        background-color: $white;
        > li {
            display: block;
        }
        a {
            color: $gray-800;
            &:hover,
            &:active,
            &:focus {
                color: $primary;
                &::before { 
                    background-color: $primary;
                }
            }
            position: relative;
            &::before { 
                content: "";
                display: block;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $gray-100;
                position: absolute;
                left: calc(.75rem - 3.5px);
                top: calc(50% - 2.5px);
            }
        }
        width: 100% !important;
        position: relative !important;
        z-index: auto !important;
        &::before {
            content: "";
            width: 1px;
            height: 100%;
            display: block;
            background-color: $gray-100;
            position: absolute;
            left: calc(.75rem - 1px);
            top: 0px;
        }
    }
    .container-row-citazionesfondo {
        background-position: center;
    }
    .heading-hover {
        margin-top: initial;
        margin-bottom: initial;
        > ul > li {
            min-height: 1px;
            display: block !important;
        }
    }
    // .navbar-expand-md .navbar-nav {
    //     .dropdown-menu {
    //         padding: 0px;
    //         list-style: none;
    //         > li {
    //             list-style: none !important;
    //             display: block !important;
    //             a {
    //                 color: $gray-800;
    //                 text-align: left;
    //             }
    //         }
    //     }
    //     .dropdown-menu {
    //         background-color: $white;
    //         position: relative;
    //         top: auto !important;
    //         left: auto;
    //         max-height: 0px;
    //         overflow: hidden;
    //         &.show {
    //             max-height: 300vh;
    //         }
    //     }
    //     .nav-link {
    //         width: 100%;
    //     }
    // }
    #main-menu {
        max-height: 90vh;
        overflow: auto;
    }
    .row{
        &--5-1000 { 
            margin-bottom: 2.5rem;
            &:last-child() {
                margin-bottom: 0px;
            }
            .btn-outline-withe {
                margin-top: 5px !important;
                margin-bottom: 15px;
                font-size: 20px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }
    .shortcode-title {
        margin-bottom: calc(1.25rem - 10px);
    }
    .row .list-category {
        display: block !important;
        a {
            margin-top: 10px;
            margin-right: 10px;
            margin-bottom: 0px;
            display: inline-block !important;
        }
    }

    .wp-block-columns .wp-block-column {
        flex-basis: 100% !important;
    }
    .wp-block-columns .wp-block-column:nth-child(2n) {
        margin-left: 0;
    }
}
@media only screen and (max-width : 480px) {}
@media only screen and (max-width : 320px) {
    #cookie-notice .cn-button.bootstrap, 
    .btn, .form-check--btn input[type=checkbox]+label, 
    .form-check--btn input[type=radio]+label, 
    .row--5-1000 .btn-outline-withe, .wp-block-button__link, .wpcf7 input[type=submit] {
        white-space:normal !important;
    }
}