@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;700&display=swap');
::selection { background: $primary; color: $white; }
::-moz-selection { background: $primary; color: $white; }   
// Font
// Generale
// #content {
//     min-height: 25vh;
// }
*{
    outline: none !important;
}
#page-wrapper{
    padding: 0;
}
.draggable-false {	
	user-drag: none; 
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}
i { font-style: normal; }
a { text-decoration: none !important; }
.btn { 
    padding: 18px 50px; 
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 1;  
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 400;
    transition: all .3s ease-in-out;
}
.btn-green {
    color: #fff;
    background-color: $green;
    border-color: $green;
    &:hover,
    &:focus,
    &:active {
        color: $primary;
        background-color: darken( $green, 20% );
        border-color: darken( $green, 20% );
    }
}
.btn-yellow {
    color: $primary;
    background-color: $yellow;
    border-color: $yellow;
    &:hover,
    &:focus,
    &:active {
        color: $primary;
        background-color: darken( $yellow, 20% );
        border-color: darken( $yellow, 20% );
    }
}
.btn-white {
    color: $primary;
    background-color: $white;
    border-color: $white;
    &:hover,
    &:focus,
    &:active {
        color: $primary;
        background-color: $secondary;
        border-color: $secondary;
    }
}
.wpcf7 input[type=submit] {
    @extend .btn;
    @extend .btn-primary;
    color: $white !important;
    &:hover,
    &:focus,
    &:active {
        color: $primary;
        background-color: darken( $primary, 20% );
        border-color: darken( $primary, 20% );
    }   
}
p {
    @include media-breakpoint-down(sm){
        margin-bottom: 1.25rem;
    }
}
.wrapper {
    // overflow-x: hidden;
    padding: 4rem 0px;
    &-newsletter {
        padding-bottom: calc(4rem - 10px);
    }
    &-articoli-suggeriti {
        padding-bottom: calc(4rem - 1.25rem);
        h2 {
            font-weight: 700;
            font-size: 1.875rem;
        }
    }
    &--medium {
        padding-top: 4rem;
        padding-bottom: 4rem;
        & + .wrapper--medium {
            padding-top: 0px;
        }
    }

    
    @include media-breakpoint-down(sm){
        padding: 4rem 0px;
        &--medium {
            padding-top: 4rem;
            padding-bottom: 4rem;
            & + .wrapper--medium {
                padding-top: 0px;
            }
        }
    }
}
h5 {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
}
// h3 {
//     font-size: 1.5rem;
//     margin-bottom: 1.25rem;
// }
// h1, h2, h3, h4 {
//     font-weight: 700;
//     color: $primary;
// }
h1, .h1 {
    font-weight: 700;
}
h2, .h2 {
    margin-bottom: 1.25rem;
}
h4,.h4 {
    color: $gray-800;
    margin-bottom: 0.8em;
}
b, strong {
    font-weight: 700;
}
h3, h2, .h3, .h2 { 
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: 1.25rem;
    color: $primary;
    @include media-breakpoint-up(lg){
        font-size: 40px;
    } 
}
.entry-content,
.site-main {
    > h2 {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
    }
    
    // Punti elenco.
    ul, ol {
        &:not(.filter-menu):not(.disable-marker) {            
            // font-size: 0.875rem;
            font-size: 1rem;
            // line-height: 1.0625rem;
            padding: 0px;
            list-style: none;
            li {
                position: relative;
                padding-left: 1.5625rem;
                margin-bottom: .5rem;
                &::before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 0px;
                    width: 17px;
                    height: 17px;
                    display: block;
                    background-image: url(../img/svg/check-circle.svg);
                    background-size: cover;
                    background-position: center;
                }
                &:last-child() {
                    margin-bottom: 0px;
                }
                > a {
                    font-weight: 700;
                    color: $gray-800;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }
    ol  {
        &:not(.filter-menu):not(.disable-marker) {     
            li {
                &::before {
                    background-image: url(../img/svg/x-circle.svg);
                }
            }
        }
    }
    ul, ol {
        &:not(.filter-menu):not(.disable-marker) {       
            &.links-list {
                line-height: 1.4;
                font-size: 1rem;
                li {
                    &::before {
                        background-image: url(../img/svg/external-link.svg);
                    }
                }
            }
        }
    }
}
.btn-primary--animation {
    color: $gray-800;
    font-weight: 700;
    font-size: 0.875rem;
    &::before, 
    &::after {
        content: "";
        display: inline-block;
        // position: absolute;
        width: 50px;
        height: 2px;
        background-color: $primary;
    }
    &::before { margin-right: 0px; max-width: 0px;  }
    &::after { margin-left: 0.9375rem; max-width: 50px; }
    &:hover {                
        &::before { margin-right: 0.9375rem; max-width: 50px;  }
        &::after { margin-left: 0px; max-width: 0px; }
    } 
    border: 0px !important;
    padding: 4px 0px;
    box-shadow: none !important;
    outline: none !important;
}
.entry-content,
.site-main {
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > p,
    > ul,
    > ol {
        &:first-child() {
            margin-top: 2.5rem;
        }
        &:last-child() {
            margin-bottom: 2.5rem;
        }
    }
}
// Tabella 5x1000
.row{
    &--5-1000 { 
        .btn-outline-withe {
            @extend .btn;
            @extend .btn-outline-light;
            @extend .rounded-0;

            color: $white;
            border-color: $white;
            width: 100%;
            font-weight: 700;
            font-size: 1.875rem;
            margin-bottom: 1.25rem;

            &:hover,
            &:active,
            &:focus {
                color: $white !important;
                border-color: $white !important;
                background-color: transparent !important;
            }
            cursor: default !important;
        }
        &:last-child() {
            &--5-1000 { 
                .btn-outline-withe {
                    margin-bottom: 0px;
                }
            }
        }
    }
    .d-flex {
        min-height: 64px;
        margin-bottom: 1.25rem;
    }
    .col--text {
        color: $white;
        p {
            margin-bottom: 0px;
        }
        span {
            margin-right: 1.25rem;     
            font-size: 1.875rem;
            font-weight: 700;
            margin-bottom: 0px;
            min-width: 20px;
            text-align: center;
        }
        svg {
            width: 35px;
            min-width: 35px;
            height: auto;
            margin-right: 1.25rem;   
        }
    }
}
// Carosello di immagini.
// .carousel-inner {
//     background-color: $gray-800;
// }
.carousel-control-next-icon {
    background-image: url(../img/svg/Arrow-next.svg);
}
.carousel-control-prev-icon {
    background-image: url(../img/svg/Arrow-prev.svg);
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    height: 45px;
}
// Formattazione form.
.wpcf7 {
    max-width: 100% !important;
}
// .wpcf7-form {
//     > .form-row {
//         margin-top: 2.5rem;        
//     }
// }
input[type=text].form-control,
input[type=email].form-control,
input[type=tel].form-control,
textarea.form-control {
    border-radius: 0px;
    border: 0px;
    background-color: $white;
    border-bottom: 1px solid $primary;
    padding: 0.875rem 1.25rem;
    font-size: .875rem;  
    outline: none !important;
    box-shadow: none !important;
    height: 48px;
}
textarea.form-control {
    height: auto;
}
// input[type=checkbox].form-control {
//     width: auto;
//     height: 48px;
//     margin-right: 10px;
//     outline: none !important;
//     box-shadow: none !important;
//     float: left;
// }
// span.wpcf7-list-item {
//     margin-left: 0px;
// }
// .wpcf7-acceptance {
//     font-size: 0.875rem;
//     span {
//         line-height: 48px;
//         float: left;
//     }
//     a {
//         color: $primary  !important;
//         &:hover {
//             color: darken($primary, 20%) !important;
//         }
//     }
// }
.btn, .wpcf7 input[type=submit] {
    height: auto;
}
.form-control:disabled, .form-control[readonly], .wpcf7 .wpcf7-validation-errors:disabled, .wpcf7 .wpcf7-validation-errors[readonly], .wpcf7 input:disabled[type=color], .wpcf7 input:disabled[type=date], .wpcf7 input:disabled[type=datetime-local], .wpcf7 input:disabled[type=datetime], .wpcf7 input:disabled[type=email], .wpcf7 input:disabled[type=file], .wpcf7 input:disabled[type=month], .wpcf7 input:disabled[type=number], .wpcf7 input:disabled[type=range], .wpcf7 input:disabled[type=search], .wpcf7 input:disabled[type=submit], .wpcf7 input:disabled[type=tel], .wpcf7 input:disabled[type=text], .wpcf7 input:disabled[type=time], .wpcf7 input:disabled[type=url], .wpcf7 input:disabled[type=week], .wpcf7 input[readonly][type=color], .wpcf7 input[readonly][type=date], .wpcf7 input[readonly][type=datetime-local], .wpcf7 input[readonly][type=datetime], .wpcf7 input[readonly][type=email], .wpcf7 input[readonly][type=file], .wpcf7 input[readonly][type=month], .wpcf7 input[readonly][type=number], .wpcf7 input[readonly][type=range], .wpcf7 input[readonly][type=search], .wpcf7 input[readonly][type=submit], .wpcf7 input[readonly][type=tel], .wpcf7 input[readonly][type=text], .wpcf7 input[readonly][type=time], .wpcf7 input[readonly][type=url], .wpcf7 input[readonly][type=week], .wpcf7 select:disabled, .wpcf7 select[readonly], .wpcf7 textarea:disabled, .wpcf7 textarea[readonly] {
    background-color: $gray-100;
    border-color: $gray-100;
    color: $gray-500 !important;
}
.form-control.wpcf7-not-valid {
    color: $danger;
    border-color: $danger;
} 
.wpcf7 .wpcf7-not-valid-tip {
    margin-top: .3125em;
    font-size: 0.75rem;
    font-weight: 700;
    color: $danger;
}
div.wpcf7 {
    .ajax-loader {
        display: none !important;
    }                
    .wpcf7-response-output {
        margin: 2.5rem 0px 0px !important;
        padding: 1rem !important;
        height: auto !important;
        text-align: center;
        font-size: 0.75rem;
        font-weight: 700;
        border:0px;
    }
    span.wpcf7-list-item {
        margin-left: 0px;
    }        

    .wpcf7-form {
        .wpcf7-response-output {
            color: darken($success, 15%);
            background-color: lighten($success, 30%);
        }
        &.invalid {
            .wpcf7-response-output {
                color: darken($danger, 15%);
                background-color: lighten($danger, 30%);
            }
        }
        .wpcf7-list-item {
            label {
                padding-left: 25px;
                position: relative;
                input {
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .wpcf7-checkbox {
            label {
                input {
                    top: 5px;
                }
            }
        }
    }

    .wpcf7-checkbox {
        span {
            &.wpcf7-list-item {
                display: block;
            }
        }
    }
}
ul {
    &:last-child {
        margin-bottom: 0px;
    }
}
// Menu delle categorie del blog.
.filter-menu {
    padding: 0px;
    margin: 0px;
    list-style: none;
    li {
        .list-category__link--notizie {
            color: $white;
            &:active,
            &:focus,
            &:hover {
                color: darken($primary, 20%) !important;
            }
        }
        padding: 0px;
        margin: 0px;
        list-style: none;
        margin-right: 30px;
        &.list-category {
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 700;
        }
        &:last-child {
            margin-right: 0px;
        }
        &.active {
            .btn-outline-light {
                color: #1e1e1e;
                background-color: #f1f1f1;
                border-color: #f1f1f1;
            }
        }
    }
    .btn {
        padding: 0.375rem 1rem;
    }
    &-wrapper {
        background-color: $primary;
        color: $white;
        font-size: 0.875rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
        .btn-primary--animation {
            color: $white;
            &::before, 
            &::after {
                background-color: $white;
            }
            padding: 0px;
            line-height: 28px;
        }
    }
}
// Post
.single-post {
    .entry-content {
        padding-top: 3.75rem;
        padding-bottom: 3.75rem;
    } 
    .entry-header-sizer {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            mix-blend-mode: multiply;
            /* opacity: 0.3; */
            background-color: rgba(40, 40, 40, 0.3);
        }
        > .container {
            z-index: 2;
        }
    }
    
    @include media-breakpoint-down(sm){
        .entry-content {
            padding-top: 1.875rem;
            padding-bottom: 1.875rem;
        } 
	}
}
.card-body {
    .sticky-top {
        top: calc(80px + 3.75rem);
        z-index: 10;
    }
}

.wp-block-embed, 
.wp-block-image { 
    figcaption {
        @include media-breakpoint-down(sm){
            padding-left: 0px;
        }
    }
}
.wp-block-group,
.wp-block-columns {
    margin-bottom: 3.75rem;
    &:first-child {
        margin-top: 3.75rem;
    }
    @include media-breakpoint-down(sm){
        margin-bottom: 1.875rem;
        &:first-child {
            margin-top: 1.875rem;
        }
        .wp-block-column {
            margin-top: 0px;
            margin-bottom: 1.875rem;
            > *:last-child {
                margin-bottom: 0px;
            }
            &:empty{
                display: none;
            }
        }
        &.wrapper--medium {
            padding-bottom: 0px;            
        }
	}
}
.wp-block-columns + .wp-block-group,
.wp-block-columns + .wp-block-columns,
.wp-block-group + .wp-block-columns,
.wp-block-group + .wp-block-group,
.wrapper--medium + .wp-block-columns ,
.wrapper--medium + .wp-block-group{
    margin-top: 0px;
}
.wp-block-columns + .wrapper--medium,
.wp-block-group + .wrapper--medium {
    padding-top: 0px;
}
.wp-block-column {
    p {
        &:last-child {
            margin-bottom: 0px;
        }
    }
}
p:empty {
    display: none;
}
.wp-block-buttons .wp-block-button {
    margin-bottom: 0px;
}

// Riga estasa oltre il bordo
.outer-row.has-background {
    padding: 3.75rem 0px;
    position: relative;
    &:before {
        content: "";
        width: 100vw;
        height: 100%;
        display: block;
        position: absolute;
        top: 0px;
        left: -15px;
        background-color: $gray-100;
        z-index: -1;
    }
    > h3 { 
        &:first-child {
            margin-top: 0px;
        }
    }
    .wp-block-group,
    .wp-block-columns {
        &:last-child {
            margin-bottom: 0px;
        }
        &:first-child {
            margin-top: 0px;
        }
    }

    @include media-breakpoint-down(sm){
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
	}
}
// Decorazione esta oltreil bordo.
.divider-img {
    min-height: 35px;
    position: relative;
    figure {
        position: absolute;
        top: 0px;
        width: 100vw;
        min-height: 35px;
        display: block;
        left: -15px;
        img, svg {
            // position: absolute;
            // top: 0px;
            // left: calc(((2000px - 100vw)/2)*-1);
            // width: 2000px;
            // height: 33px;
            // object-fit: cover;
            width: auto;
            min-height: 35px;
            display: block;
            object-fit: cover;
        }
    }
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .outer-row.has-background {
        &:before {
            left: calc(((100vw - 493px) / 2) * -1);
        }
    }
    .divider-img {
        figure {
            left: calc(((100vw - 493px) / 2) * -1);
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .outer-row.has-background {
        &:before {
            left: calc(((100vw - 690px) / 2) * -1);
        }
    }
    .divider-img {
        figure {
            left: calc(((100vw - 690px) / 2) * -1);
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .outer-row.has-background {
        &:before {
            left: calc(((100vw - 937px) / 2) * -1);
        }
    }
    .divider-img {
        figure {
            left: calc(((100vw - 937px) / 2) * -1);
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    .outer-row.has-background {
        &:before {
            left: calc(((100vw - 1093px) / 2) * -1);
        }
    }
    .divider-img {
        figure {
            left: calc(((100vw - 1093px) / 2) * -1);
        }
    }
}

// Border frame with designs.
.borderframe-01 {
    position: relative;
    padding-top: 120px;
    padding-bottom: 60px;
    @include media-breakpoint-up(md){
        padding-top: 160px;
        padding-bottom: 100px;
    }
    .btn, .wp-block-buttons, 
    .wp-block-button {
        &:last-child {
            margin-bottom: 0px;
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0;
        // right: -15%;
        width: 100%;
        // height: auto;
        // height: 100%;
        padding-bottom: 129%;
        z-index: 1;
        background-image: url(../img/svg/cara_della_carita_border.svg);
        background-repeat: no-repeat;
        background-position: center left;
        background-size: contain;
        @include media-breakpoint-only(lg){
            padding-top: 160px;
            padding-bottom: 100px;
            display: none;
        }
    }
    > * {
        position: relative;
        z-index: 5;
        max-width: 340px;
    }
    
    @include media-breakpoint-down(sm){
        padding-top: 0px;
        padding-bottom: 0px;            
        &::before {
            position: relative;
            top: auto;
            left: auto;
            display: block;
            padding: 0px;
            height: 380px;
            margin-bottom: 1.25rem;
        }
        > * {
            max-width: 100%;
        }
        > h2 {
            max-width: 220px;
            position: absolute;
            top: 145px;
        }
        a.btn {
            margin-top: 0px;
            margin-bottom:  1.25rem !important;
        }
    }
}

.custom-readmore-p {
    font-size: 18px;
    @include media-breakpoint-up(md){
        text-align: right;
    }
    a {
        font-weight: 700;
    }
}

%secondary-content {
    background-color: $secondary;
    color: #FFFFFF;
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        color: #FFFFFF;
    }
    // a {
    //     // color: $primary;
    //     &:hover,
    //     &:focus {
    //         // color: $primary-lighter;
    //     }
    // }
    // .content-titolo {
    //     // color: $primary;
    // }
    // .btn {
    //     &-primary,
    //     &-outline-primary,
    //     &-outline-secondary, 
    //     &-secondary {
    //     //     &:hover,
    //     //     &:focus {
    //     //         // color: #FFFFFF;
    //     //     }
    //     // }
    // }
}

%primary-content {
    background-color: $primary;
    color: #FFFFFF;
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        color: #FFFFFF;
    }
    a,
    .btn-link {
        color: $white;
        &:hover,
        &:focus {
            color: $yellow;
        }
    }
    
}

.content-primary {
    @extend %primary-content;
}
.content-secondary {
    @extend %secondary-content;
}

.btn {
    &-primary,
    &-secondary {
        color: #FFFFFF;
        &:hover,
        &:focus {
            color: #FFFFFF;
        }
    }
    &-primary {
        &:hover,
        &:focus {
            background-color: $secondary;
            border-color: $secondary;
        }
    }
    &-secondary {
        &:hover,
        &:focus {
            background-color: $primary;
            border-color: $primary;
        }
    }
    // &-yellow {
    //     &:hover,
    //     &:focus {
    //         background-color: $primary;
    //         border-color: $primary;
    //         color: $white;
    //     }
    // }
}

.content-primary,
.content-secondary,
.wrapper-testoimmagini-inverse, 
.wrapper-testoimmagini-layout_02 {
    .btn {
        &-primary,
        &-secondary {
            &:hover,
            &:focus {
                background-color: $white;
                border-color: $white;
                color: $primary;
            }
        }
        // &-yellow {
        //     &:hover,
        //     &:focus {
        //         background-color: $white;
        //         border-color: $white;
        //         color: $primary;
        //     }
        // }
    }
}

.site-slider {
    .btn {
        &-primary,
        &-secondary,
        &-yellow  {
            &:hover,
            &:focus {
                background-color: $white;
                border-color: $white;
                color: $primary;
            }
        }
    }
}
.p-margin {
    margin-bottom: 1.875rem;
    @include media-breakpoint-down(sm){
        margin-bottom: 1.875rem;
	}
}

figcaption {
    font-size: 11px;
    padding-left: 30px;
    padding-top: 5px;
    text-transform: uppercase;
    color: #777;
    .content-primary &,
    .content-secondary & {
        color: #FFFFFF;
    }
    &:last-child() {
        margin-bottom: 0px;
    }
}
.side-text {
    @include media-breakpoint-up(md){
        max-width: 400px;
    }
}

select.form-control {
    border-radius: 0;
    border: none;
    height: 48px;
}

.wp-block-quote {
    &.is-large, 
    &.is-style-large {
        padding: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        text-transform: none;
    }
}

.embed-responsive-item{
    & img, video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.col-overflow{
    // margin-top: 20px;
    // margin-bottom: 20px;
    @include media-breakpoint-up(md){
        background-color: $white;
        padding-top: 50px;
        padding-bottom: 25px;
        z-index: 1;
    }
    // @include media-breakpoint-up(lg){
    //     margin-top: auto;
    //     margin-bottom: 40px;
    // }
    @include media-breakpoint-up(xl){
        padding-top: 100px;
        padding-bottom: 50px;
    }

    &--left{
        @include media-breakpoint-up(lg){
            padding-right: 3.5rem;
            // margin-right: calc(-8.3333% - 30px);
        }
    }
    &--right{
        @include media-breakpoint-up(lg){
            //  margin-left: calc(-8.3333% - 30px);
            padding-left: 8.3333%;
        }
    }

}

.bg-shadow{
    position: relative;
    background-color: $primary;
    &::after{
        content: none;
        position: absolute;
        display: none;
        width: 60%;
        height: 100%;
        opacity: 1;
        z-index: 1;
        @include media-breakpoint-up(md){
            content: '';
            display: block;
        }
    }
    
    &--right{
        &::after{
            bottom: 0;
            left: 0;
            @include bgSplit()
        }
    }
    
    &--left{
        &::after{
            bottom: 0;
            right: 0;
            @include bgSplit(right)
        }
    }
    
    
    &--stampa{
        background: $stampa-primary;
        &.bg-shadow--right{
            &::after{
                @include bgSplit(left, $stampa-primary, $stampa-secondary)
            }
        }
        
        &.bg-shadow--left{
            &::after{
                @include bgSplit(right, $stampa-primary, $stampa-secondary)
            }
        }
    }
    &--ins{
        background: $ins-primary;
        &.bg-shadow--right{
            &::after{
                @include bgSplit(left, $ins-primary, $ins-secondary)
            }
        }
        
        &.bg-shadow--left{
            &::after{
                @include bgSplit(right, $ins-primary, $ins-secondary)
            }
        }
    }

    &--lum{
        background: $lum-primary;
        &.bg-shadow--right{
            &::after{
                @include bgSplit(left, $lum-primary, $lum-secondary)
            }
        }
        
        &.bg-shadow--left{
            &::after{
                @include bgSplit(right, $lum-primary, $lum-secondary)
            }
        }
    }

    &--pro{
        background: $pro-primary;
        &.bg-shadow--right{
            &::after{
                @include bgSplit(left, $pro-primary, $pro-secondary)
            }
        }
        
        &.bg-shadow--left{
            &::after{
                @include bgSplit(right, $pro-primary, $pro-secondary)
            }
        }
    }

    &--alle{
        background: $alle-secondary;
        &.bg-shadow--right{
            &::after{
                @include bgSplit(left, $alle-secondary, $alle-primary)
            }
        }
        
        &.bg-shadow--left{
            &::after{
                @include bgSplit(right, $alle-secondary, $alle-primary)
            }
        }
    }
}


.wrapper-parcomacchine{
    & p{
        color: $gray-500;
        font-weight: 700;
        margin-bottom: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        position: relative;
        @include media-breakpoint-up(md){
            font-size: 18px;
        }

        &:before{
            content: '';
            position: absolute;
            display: block;
            top: 25%;
            bottom: 0;
            left: 0;
            height: 50%;
            width: .6rem;
            background: $secondary;
        }
        
    }
}




.page{
    &-stampa{
        .navbar.bg-primary,
        #wrapper-prefooter-full{
            @include bgSplit(right, $stampa-primary, $stampa-secondary)
        }
    }
    &-ins{
        .navbar.bg-primary,
        #wrapper-prefooter-full{
            @include bgSplit(right, $ins-primary, $ins-secondary)
        }
    }
    &-lum{
        .navbar.bg-primary,
        #wrapper-prefooter-full{
            @include bgSplit(right, $lum-primary, $lum-secondary)
        }
    }
    &-pro{
        .navbar.bg-primary,
        #wrapper-prefooter-full{
            @include bgSplit(right, $pro-primary, $pro-secondary)
        }
    }
    &-alle{
        .navbar.bg-primary,
        #wrapper-prefooter-full{
            @include bgSplit(right, $alle-secondary, $alle-primary)
        }
    }
}


.card-columns {
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
}

.ofi-contain{
    object-fit: contain;
    object-position: center;
    height: 80vh;
    width: 100%;
    
    @include media-breakpoint-up(md){
        height: 90vh;        
    }
}

.modal-content{
    background-color: transparent;
    pointer-events: none;
}

.modal-dialog {
    max-width: 95%;
    @include media-breakpoint-up(sm){
        max-width: 95%;
    }

    @include media-breakpoint-up(md){
        max-width: 100%;
    }
}

.color-inherit {
    color: inherit!important;
}

.bg-gradient {
    min-height: 400px!important;
    background-image: radial-gradient(circle at bottom right,#009ed4,#17375f 70%)!important;
}

.title-blog {
    h1 {
        font-weight: 700!important;
    }
    margin-bottom: 8rem;

    @include media-breakpoint-down(lg) {
        margin-bottom: 5rem;
    }

    @include media-breakpoint-down(md){
        margin-bottom: 3rem;
    }
}

.btn-categoria, .btn-categoria-2 {
    display: inline;
    margin: 10px 10px 0 0!important;
    padding: 7px 20px!important;
    background-color: $primary!important;
    color: $white;
    border-radius: 0!important;

    &:hover {
        color: $primary;
        background-color: $white!important;
    }
}

.title-pdf {
    font-size: 24px!important;
}

.download-pdf {
    font-size: 14px;
    color: #0597CD;
}

.widget_category li {
    margin-bottom: 1.5rem;
}