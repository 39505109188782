
@mixin linkChange($first, $second){
    color: $first;
    transition: $transition-base;

    &:hover,
    &:focus,
    &:active{
        color: $second;
    }

}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths-outer($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: calc(50vw + #{$container-max-width/2});
        }
    }
}

@mixin bgSplit($dir: left, $prim: $primary, $second: $secondary, $perc: 70%){
    background-image: radial-gradient(circle at bottom $dir, $second, $prim $perc);
}

@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}
@function rem($pxValue) {
    @return #{stripUnit($pxValue) / stripUnit(16px)}rem;
}
@function em($pxValue, $fontSize) {
    @return #{stripUnit($pxValue) / stripUnit($fontSize)}em;
}